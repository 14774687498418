<template>
  <CreateUpdateTemplate
    :routePreventDialog="routePreventDialog"
    :hasChanges="hasChanges"
    :customClass="'invoice-create create-form'"
    v-if="getPermission('invoice:create') || getPermission('invoice:update')"
  >
    <template v-slot:header-title>
      <div class="d-flex justify-space-between">
        <h1 class="form-title">
          Invoice for
          <span
            class="form-title-link cursor-pointer"
            v-on:click="openDialog('customer')"
          >
            <template v-if="vCustomerId">{{ vCustomer.display_name }}</template>
            <template v-else>
              Select a Customer
              <v-icon large color="cyan">mdi-plus-circle-outline</v-icon>
            </template>
          </span>
        </h1>
        <div>
          <v-chip
            v-if="dbInvoice?.barcode"
            label
            color="chip-custom-blue"
            outlined
            class="text-white p-3 mr-4"
            style="font-size: 21px !important; font-weight: 700"
          >
            <template>
              {{ dbInvoice.barcode }}
            </template>
          </v-chip>
        </div>
      </div>
    </template>
    <template v-slot:header-action
      ><div>
        <v-btn
          :disabled="formLoading"
          v-on:click="goBack() /* ;clearLocalStorage() */"
          class="custom-bold-button custom-grey-border"
          depressed
          >Cancel</v-btn
        >
        <v-btn
          class="custom-bold-button ml-4"
          depressed
          :disabled="
            !formValid ||
            formLoading ||
            (customError &&
              (dbInvoice?.billing_type == 'recurring' ||
                dbInvoice?.billing_type == 'recurring-based-on-quote'))
              ? true
              : false
          "
          :loading="formLoading"
          v-on:click="validateJob('draft')"
          color="cyan white--text"
          >Save As Draft</v-btn
        >
        <v-btn
          class="custom-bold-button ml-4"
          depressed
          :disabled="
            !formValid ||
            formLoading ||
            (customError && dbInvoice?.billing_type == 'recurring') ||
            dbInvoice?.billing_type == 'recurring-based-on-quote'
              ? true
              : false
          "
          :loading="formLoading"
          v-on:click="validateJob('send')"
          color="cyan white--text"
          >Save & Send</v-btn
        >
      </div></template
    >
    <template v-slot:body>
      <perfect-scrollbar
        :options="{ suppressScrollX: true }"
        class="scroll custom-box-top-inner-shadow product-create-height"
        style="position: relative"
      >
        <div class="bg-white px-2">
          <v-form
            ref="createInvoiceForm"
            v-model.trim="formValid"
            lazy-validation
            v-on:submit.stop.prevent="validateJob()"
          >
            <v-row>
              <v-col md="7">
                <label for="visit-title" class="required">Invoice Title</label>
                <TextField
                  id="quotation-title"
                  dense
                  filled
                  placeholder="Title"
                  solo
                  flat
                  counter="50"
                  v-model="dbInvoice.title"
                  color="cyan"
                  :maxlength="250"
                  :rules="[validateRules.required(dbInvoice.title, 'Title')]"
                />

                <TextAreaField
                  auto-grow
                  dense
                  filled
                  color="cyan"
                  v-model="dbInvoice.description"
                  placeholder="Description"
                  solo
                  flat
                  row-height="25"
                  counter="250"
                />
                <v-row>
                  <v-col md="6">
                    <v-layout class="align-center">
                      <v-flex md3
                        ><label class="my-0">Billing Location</label></v-flex
                      >
                      <v-flex md9 class="position-relative">
                        <v-icon
                          v-on:click="openDialog('billing')"
                          small
                          class="py-0 my-0"
                          color="cyan"
                          style="position: absolute; top: -8px"
                          >mdi-pencil</v-icon
                        >
                      </v-flex>
                    </v-layout>
                    <template v-if="vBilling?.id">
                      <p class="mb-0">
                        <span v-if="vBilling.property_name"
                          >{{ vBilling.property_name }}<br
                        /></span>
                        {{ vBilling.property_address }}
                      </p>
                    </template>
                    <template v-else-if="vCustomer?.id">
                      <p class="mb-0 red--text">
                        Please select billing location
                      </p>
                    </template>
                    <template v-else>
                      <p class="mb-0 red--text">Please select customer</p>
                    </template>
                    <v-layout class="mt-3 align-center">
                      <v-flex md3
                        ><label class="my-0">Contact Details</label></v-flex
                      >
                      <v-flex md9 class="position-relative">
                        <v-icon
                          small
                          v-on:click="openDialog('billing-contact')"
                          class="py-0 my-0"
                          color="cyan"
                          style="position: absolute; top: -8px"
                          >mdi-pencil</v-icon
                        >
                      </v-flex>
                    </v-layout>
                    <template v-if="vBillingContact?.id">
                      <p class="mb-0">{{ vBillingContact.display_name }}</p>
                      <p class="mb-0">{{ vBillingContact.primary_phone }}</p>
                      <p class="mb-0">{{ vBillingContact.primary_email }}</p>
                    </template>
                    <template v-else-if="vCustomer?.id">
                      <p class="mb-0 red--text">Please select contact person</p>
                    </template>
                    <template v-else>
                      <p class="mb-0 red--text">Please select customer</p>
                    </template>
                    <v-layout class="mt-3">
                      <v-flex md4
                        ><label class="my-0">Email Notification </label></v-flex
                      >
                      <v-flex md8>
                        <v-switch
                          v-model="dbInvoice.notify_billing_contact_person"
                          class="m-0 p-0"
                          color="cyan"
                          :false-value="0"
                          :true-value="1"
                          dense
                          inset
                        />
                      </v-flex>
                    </v-layout>
                  </v-col>
                  <v-col md="6">
                    <v-layout class="align-center">
                      <v-flex md3
                        ><label class="my-0">Site Location</label></v-flex
                      >
                      <v-flex md9 class="position-relative">
                        <v-icon
                          small
                          v-on:click="openDialog('property')"
                          class="py-0 my-0"
                          color="cyan"
                          style="position: absolute; top: -8px"
                          >mdi-pencil</v-icon
                        >
                      </v-flex>
                    </v-layout>

                    <template v-if="vProperty?.id">
                      <p class="mb-0">
                        <span v-if="vProperty.property_name"
                          >{{ vProperty.property_name }}<br
                        /></span>
                        {{ vProperty.property_address }}
                      </p>
                    </template>
                    <template v-else-if="vCustomer?.id">
                      <p class="mb-0 red--text">
                        Please select service location
                      </p>
                    </template>
                    <template v-else>
                      <p class="mb-0 red--text">Please select customer</p>
                    </template>

                    <v-layout class="mt-3 align-center">
                      <v-flex md3
                        ><label class="my-0">Contact Details</label></v-flex
                      >
                      <v-flex md9 class="position-relative">
                        <v-icon
                          small
                          v-on:click="openDialog('property-contact')"
                          class="py-0 my-0"
                          color="cyan"
                          style="position: absolute; top: -8px"
                          >mdi-pencil</v-icon
                        >
                      </v-flex>
                    </v-layout>

                    <template v-if="vPropertyContact?.id">
                      <p class="mb-0">{{ vPropertyContact.display_name }}</p>
                      <p class="mb-0">{{ vPropertyContact.primary_phone }}</p>
                      <p class="mb-0">{{ vPropertyContact.primary_email }}</p>
                    </template>
                    <template v-else-if="vCustomer?.id">
                      <p class="mb-0 red--text">Please select contact person</p>
                    </template>
                    <template v-else>
                      <p class="mb-0 red--text">Please select customer</p>
                    </template>

                    <v-layout class="mt-3">
                      <v-flex md4
                        ><label class="my-0">Email Notification </label></v-flex
                      >
                      <v-flex md8>
                        <v-switch
                          v-model="dbInvoice.notify_property_contact_person"
                          class="m-0 p-0"
                          color="cyan"
                          :false-value="0"
                          :true-value="1"
                          :disabled="formLoading"
                          dense
                          inset
                        />
                      </v-flex>
                    </v-layout>
                  </v-col>
                </v-row>
              </v-col>
              <v-col md="5">
                <table
                  width="100%"
                  style="table-layout: fixed"
                  v-if="is_allowed_recur_invoice"
                >
                  <tr>
                    <!-- <td>
                        <label for="invoice-id">Type</label>
                      </td> -->
                    <td colspan="2" width="100%">
                      <div class="d-flex mt-4">
                        <v-btn
                          depressed
                          tile
                          :outlined="!(dbInvoice.billing_type == 'one-off')"
                          v-on:click="dbInvoice.billing_type = 'one-off'"
                          value="one-off"
                          color="red darken-3 white--text"
                          :disabled="
                            invoiceEditId &&
                            (dbUpdateInvoice?.invoice?.billing_type ==
                              'recurring' ||
                              dbUpdateInvoice?.invoice?.billing_type ==
                                'recurring-based-on-quote')
                              ? true
                              : false
                          "
                          style="font-size: 15px; margin-right: 20px"
                          >One-Off</v-btn
                        >
                        <v-btn
                          style="font-size: 15px; margin-right: 20px"
                          class="mx-2"
                          depressed
                          tile
                          :outlined="!(dbInvoice.billing_type == 'recurring')"
                          v-on:click="dbInvoice.billing_type = 'recurring'"
                          value="recurring"
                          color="green darken-4 white--text"
                          :disabled="
                            invoiceEditId &&
                            dbUpdateInvoice?.invoice?.billing_type == 'one-off'
                              ? true
                              : false
                          "
                          >Recurring</v-btn
                        >
                      </div>
                    </td>
                  </tr>
                </table>
                <table
                  width="100%"
                  style="table-layout: fixed"
                  v-if="
                    (dbInvoice?.billing_type == 'recurring' ||
                      dbInvoice?.billing_type == 'recurring-based-on-quote') &&
                    !invoiceEditId
                  "
                >
                  <tr>
                    <td width="100%">
                      <div>
                        <RecurringSchedule
                          :key="`job-recurring-schedule-${scheduleKey}`"
                        />
                      </div>
                    </td>
                  </tr>
                </table>
                <table width="100%" style="table-layout: fixed">
                  <tr
                    v-if="
                      (dbInvoice?.billing_type == 'recurring' ||
                        dbInvoice?.billing_type ==
                          'recurring-based-on-quote') &&
                      !invoiceEditId
                    "
                  >
                    <td width="20%" v-if="false">
                      <label for="invoice-id">Type</label>
                    </td>
                    <td width="80%" v-if="false">
                      <v-autocomplete
                        id="payment-due"
                        dense
                        :disabled="formLoading"
                        :loading="formLoading"
                        v-model="dbInvoice.recurring_type"
                        item-text="text"
                        item-value="value"
                        item-color="cyan"
                        filled
                        :items="recurringTypeList"
                        hide-details
                        placeholder="Recurring Invoive"
                        solo
                        flat
                        color="cyan"
                      />
                    </td>
                    <!-- <td width="40%">
                        <v-text-field
                         v-on:keypress="isNumber()" 
                          type="number"
                          dense
                          filled
                          hide-details
                          placeholder="Duration"
                          solo
                          flat
                          :disabled="formLoading"
                          :loading="formLoading"
                          :value="dbInvoice.recurring_duration_new"
                          color="cyan"
                        />   
                      </td> -->
                  </tr>
                  <tr v-if="false">
                    <td width="20%" colspan="2">
                      <label for="invoice-id">Invoice #</label>
                    </td>
                    <td>
                      <v-text-field
                        id="invoice-id"
                        dense
                        filled
                        readonly
                        hide-details
                        placeholder="Invoice #"
                        :maxlength="250"
                        solo
                        flat
                        :disabled="formLoading"
                        :loading="formLoading"
                        :value="dbInvoice.barcode"
                        color="cyan"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td width="20%">
                      <label for="reference">Reference</label>
                    </td>
                    <td width="80%" colspan="2">
                      <v-text-field
                        id="reference"
                        dense
                        filled
                        v-model="dbInvoice.customer_ref"
                        :maxlength="250"
                        hide-details
                        placeholder="Reference"
                        solo
                        flat
                        :disabled="formLoading"
                        :loading="formLoading"
                        color="cyan"
                      />
                    </td>
                  </tr>
                  <tr
                    v-if="
                      (dbInvoice.billing_type != 'recurring' &&
                        !invoiceEditId) ||
                      invoiceEditId > 0
                    "
                  >
                    <td width="20%">
                      <label for="invoice-date">Invoice Date</label>
                    </td>
                    <td width="80%" colspan="2">
                      <date-picker
                        :disabled="formLoading"
                        :loading="formLoading"
                        key="invoice-date"
                        id="invoice-date"
                        :min-date="todayDate"
                        placeholder="Invoice Date"
                        v-model="dbInvoice.date"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td width="20%">
                      <label for="payment-due">Payment due</label>
                    </td>
                    <td width="80%" colspan="2">
                      <v-autocomplete
                        id="payment-due"
                        dense
                        :disabled="formLoading"
                        :loading="formLoading"
                        v-model="dbInvoice.payment_due"
                        item-text="text"
                        item-value="id"
                        item-color="cyan"
                        filled
                        :items="paymentDueList"
                        hide-details
                        placeholder="Payment due"
                        solo
                        flat
                        color="cyan"
                      />
                    </td>
                  </tr>
                  <tr v-if="dbInvoice.payment_due == 5">
                    <td width="20%">
                      <label for="due-date">Due date</label>
                    </td>
                    <td width="80%" colspan="2">
                      <date-picker
                        :disabled="formLoading"
                        :loading="formLoading"
                        key="due-date"
                        id="due-date"
                        :min-date="dbInvoice.date"
                        placeholder="Due Date"
                        v-model="dbInvoice.due_date"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td colspan="3">
                      <div
                        class="font-weight-600"
                        style="color: black; font-size: 15px"
                      >
                        {{ $route?.query?.message }}
                      </div>
                    </td>
                  </tr>
                  <!--  <tr v-if="dbQuotation?.id">
              <td>
                <label for="quotation-id">Quotation</label>
              </td>
              <td>
                <v-text-field
                  id="quotation-id"
                  dense
                  filled
                  readonly
                  :value="`${dbQuotation.barcode} - ${dbQuotation.title}`"
                  hide-details
                  placeholder="Quotation"
                  solo
                  flat
                  :disabled="formLoading"
                  :loading="formLoading"
                  color="cyan"
                />
              </td>
            </tr>
            <tr v-else>
              <td>
                <label for="quotation-list">Quotation</label>
              </td>
              <td>
                <v-autocomplete
                  id="quotation-list"
                  dense
                  :items="vRelatedQuotations"
                  filled
                  v-model="dbInvoice.quotation"
                  hide-details
                  placeholder="Quotation"
                  item-text="text"
                  item-color="cyan"
                  item-value="value"
                  solo
                  flat
                  :disabled="formLoading"
                  :loading="formLoading"
                  color="cyan"
                />
              </td>
            </tr> -->
                  <!--  <tr v-if="dbVisit?.id">
              <td>
                <label for="visit-id">Visit</label>
              </td>
              <td>
                <v-text-field
                  id="visit-id"
                  dense
                  filled
                  readonly
                  :value="`${dbVisit?.visit?.barcode} - ${dbVisit?.visit?.title}`"
                  hide-details
                  placeholder="Visit"
                  solo
                  flat
                  :disabled="formLoading"
                  :loading="formLoading"
                  color="cyan"
                />
              </td>
            </tr>
            <tr v-else>
              <td>
                <label for="visit-list">Visit</label>
              </td>
              <td>
                <v-autocomplete
                  id="visit-list"
                  dense
                  :items="vRelatedVisits"
                  filled
                  v-model="dbInvoice.visit"
                  hide-details
                  placeholder="Visit"
                  item-text="text"
                  item-color="cyan"
                  item-value="value"
                  solo
                  flat
                  :disabled="formLoading"
                  :loading="formLoading"
                  color="cyan"
                />
              </td>
            </tr> -->
                </table>
              </v-col>
              <v-col cols="12" ref="qtLineItem">
                <v-card flat class="custom-grey-border remove-border-radius">
                  <v-card-title class="headline grey lighten-4">
                    <h3
                      class="font-weight-700 custom-headline color-custom-blue"
                    >
                      Line Items
                    </h3>
                  </v-card-title>
                  <v-card-text class="p-6 font-size-16">
                    <line-item
                      is-job
                      :related-type="related_type"
                      :related-detail="dbUpdateInvoice?.invoice"
                      :key="`invoice-line-item-${uniqueId}`"
                      is-invoice
                    />
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="12" ref="qtPaymentTerm">
                <v-card flat class="custom-grey-border remove-border-radius">
                  <v-card-title class="headline grey lighten-4">
                    <h3
                      class="font-weight-700 custom-headline color-custom-blue"
                    >
                      PAYMENT TERMS
                    </h3>
                  </v-card-title>
                  <v-card-text class="p-6 font-size-16">
                    <v-autocomplete
                      v-if="false"
                      dense
                      color="cyan"
                      filled
                      label="Payment Terms"
                      solo
                      flat
                      class="my-2"
                      item-color="cyan"
                      item-text="label"
                      item-value="id"
                      hide-details
                      :disabled="formLoading"
                      :loading="formLoading"
                      :items="paymentTermList"
                      v-model="dbInvoice.payment_term_id"
                      v-on:change="update_payment_term()"
                    >
                    </v-autocomplete>
                    <TinyMCE v-model="dbInvoice.payment_terms" />
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="12" ref="qtTermCondition">
                <v-card flat class="custom-grey-border remove-border-radius">
                  <v-card-title class="headline grey lighten-4">
                    <h3
                      class="font-weight-700 custom-headline color-custom-blue"
                    >
                      Terms &amp; Conditions
                    </h3>
                  </v-card-title>
                  <v-card-text class="p-6 font-size-16">
                    <v-autocomplete
                      dense
                      color="cyan"
                      filled
                      label="Terms &amp; Conditions"
                      solo
                      flat
                      class="my-2"
                      item-color="cyan"
                      item-text="label"
                      item-value="id"
                      hide-details
                      :disabled="formLoading"
                      :loading="formLoading"
                      :items="termsConditionList"
                      v-model="dbInvoice.term_condition_id"
                      v-on:change="update_term_condition()"
                    >
                    </v-autocomplete>
                    <TinyMCE v-model="dbInvoice.term_conditions" />
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="12" class="pb-15" ref="qtNote">
                <v-card flat class="custom-grey-border remove-border-radius">
                  <v-card-title class="headline grey lighten-4">
                    <h3
                      class="font-weight-700 custom-headline color-custom-blue"
                    >
                      INTERNAL NOTES &amp; ATTACHMENTS
                    </h3>
                  </v-card-title>
                  <v-card-text class="p-6 font-size-16">
                    <v-layout>
                      <v-flex class="mr-4" md6>
                        <label class="font-size-16 font-weight-600"
                          >Internal Note</label
                        >
                        <v-autocomplete
                          dense
                          color="cyan"
                          filled
                          label="Internal Note"
                          solo
                          flat
                          class="my-2"
                          item-color="cyan"
                          item-text="label"
                          item-value="id"
                          hide-details
                          :disabled="formLoading"
                          :loading="formLoading"
                          :items="adminNoteList"
                          v-model="dbInvoice.admin_note_id"
                          v-on:change="update_admin_note()"
                          v-if="false"
                        >
                        </v-autocomplete>
                        <TinyMCE v-model="dbInvoice.admin_note" />
                      </v-flex>
                      <v-flex md6>
                        <label class="font-size-16 font-weight-600"
                          >Client Note
                          <span class="text--secondary font-weight-500"
                            >(visible on PDF)</span
                          ></label
                        >
                        <v-autocomplete
                          dense
                          color="cyan"
                          filled
                          label="Client Note"
                          solo
                          flat
                          class="my-2"
                          item-color="cyan"
                          item-text="label"
                          item-value="id"
                          hide-details
                          :disabled="formLoading"
                          :loading="formLoading"
                          :items="clientNoteList"
                          v-model="dbInvoice.client_note_id"
                          v-on:change="update_client_note()"
                          v-if="false"
                        >
                        </v-autocomplete>
                        <TinyMCE v-model="dbInvoice.client_note" />
                      </v-flex>
                    </v-layout>
                    <v-layout class="mt-4">
                      <v-flex md12 class="mr-4" v-if="false">
                        <label class="font-size-16 font-weight-600"
                          >Attachments</label
                        >
                        <FileUpload
                          v-model="dbInvoice.attachments"
                          :maxLimit="5"
                        >
                        </FileUpload>
                        <table width="100%" v-if="false">
                          <tbody>
                            <tr
                              v-for="(row, index) in dbInvoice.attachments"
                              :key="`prev-attach-${index}`"
                            >
                              <td width="50%" class="pr-2 py-2">
                                <v-text-field
                                  prepend-inner-icon="mdi-attachment mdi-rotate-90"
                                  placeholder="File Name"
                                  label="File Name"
                                  dense
                                  solo
                                  flat
                                  readonly
                                  color="cyan"
                                  v-model="row.name"
                                  hide-details
                                ></v-text-field>
                              </td>
                              <td width="50%" class="pl-2 py-2">
                                <v-text-field
                                  placeholder="File Name"
                                  label="File Name"
                                  dense
                                  solo
                                  flat
                                  color="cyan"
                                  v-model="row.name"
                                  hide-details
                                  class="has-delete-outer-icon"
                                  append-outer-icon="mdi-delete"
                                  v-on:click:append-outer="
                                    remove_row('dbInvoice.attachments', index)
                                  "
                                  :suffix="`.${row.ext}`"
                                ></v-text-field>
                              </td>
                            </tr>
                            <tr
                              v-for="(row, index) in attachments"
                              :key="index"
                            >
                              <td width="50%" class="pr-2 py-2">
                                <v-file-input
                                  prepend-inner-icon="mdi-attachment mdi-rotate-90"
                                  placeholder="Click here to select file"
                                  label="Click here to select file"
                                  prepend-icon=""
                                  color="cyan"
                                  solo
                                  flat
                                  :disabled="formLoading"
                                  :loading="formLoading"
                                  v-model="row.accepted_file"
                                  dense
                                  v-on:change="update_file_name(index)"
                                  hide-details
                                ></v-file-input>
                              </td>
                              <td width="50%" class="pl-2 py-2">
                                <v-text-field
                                  placeholder="File Name"
                                  label="File Name"
                                  dense
                                  solo
                                  flat
                                  :disabled="formLoading"
                                  :loading="formLoading"
                                  color="cyan"
                                  v-model="row.file_name"
                                  :maxlength="250"
                                  hide-details
                                  class="has-delete-outer-icon"
                                  append-outer-icon="mdi-delete"
                                  v-on:click:append-outer="
                                    remove_row('attachments', index)
                                  "
                                  :suffix="get_file_extension(row.file_type)"
                                ></v-text-field>
                              </td>
                            </tr>
                            <tr>
                              <td height="10px" colspan="2"></td>
                            </tr>
                            <tr>
                              <td colspan="2">
                                <v-btn
                                  color="cyan white--text"
                                  small
                                  :disabled="formLoading"
                                  v-on:click="add_attachment_row()"
                                  class="custom-bold-button"
                                  >Add More...</v-btn
                                >
                              </td>
                            </tr>
                            <tr>
                              <td height="10px" colspan="2"></td>
                            </tr>
                          </tbody>
                        </table>
                      </v-flex>
                      <v-flex md12 class="">
                        <DocumnetList
                          v-model="dbInvoice.attachments"
                          :document-type="3"
                        >
                        </DocumnetList>
                      </v-flex>
                    </v-layout>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-form>
        </div>
      </perfect-scrollbar>
      <customer-select
        :dialog="customerDialog"
        v-on:close="customerDialog = false"
      />
      <person-select
        :dialog="contactDialog"
        v-on:close="contactDialog = false"
        :type="contactType"
      />

      <address-select
        :dialog="propertyDialog"
        v-on:close="propertyDialog = false"
        :type="propertyType"
        :label="propertyLabel"
      />
    </template>
  </CreateUpdateTemplate>
</template>

<script>
import { mapGetters } from "vuex";
import CreateUpdateTemplate from "@/view/pages/partials/Create-Update-Template.vue";
import { PreventDialogEventBus } from "@/core/lib/prevent.reload.lib";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ValidationMixin from "@/core/plugins/validation-mixin";
import DatePicker from "@/view/components/QDatePicker.vue";
import LineItemV2 from "@/view/pages/partials/Line-Item-Invoice.vue";
import TinyMCE from "@/view/pages/partials/TinyMCE.vue";
import moment from "moment-timezone";
import ObjectPath from "object-path";
//import FileUpload from "@/view/components/app-component/FileInput.vue";
import DocumnetList from "@/view/pages/DocumnetList";
import {
  SET_CUSTOMER,
  SET_BILLING,
  SET_BILLING_CONTACT,
  SET_PROPERTY,
  SET_PROPERTY_CONTACT,
  RESET_CREATE_STATE,
} from "@/core/services/store/visit.module";
import { CLEAR_INVOICE } from "@/core/services/store/invoice.module";
import {
  CLEAR_CUSTOMER,
  /* GET_CUSTOMER_RELATED, */
} from "@/core/services/store/customer.module";
import {
  UPLOAD,
  QUERY,
  POST,
  PATCH,
} from "@/core/services/store/request.module";
import {
  SET_UOM,
  SET_GST,
  SET_EDIT_LINE_ITEM,
  SET_EDIT_CALCULATION,
  CLEAR_LINE_ITEM,
} from "@/core/services/store/line.item.module";
import CustomerSelect from "@/view/components/CustomerSelect.vue";
import PersonSelect from "@/view/components/PersonSelect.vue";
import AddressSelect from "@/view/components/AddressSelect.vue";
import { ErrorEventBus, InitializeError } from "@/core/lib/message.lib";
import KTCookie from "@/assets/js/components/cookie";
import CommonMixin from "@/core/plugins/common-mixin";
import RecurringSchedule from "@/view/pages/invoice/RecurringInvoiceSchedule.vue";
import TextAreaField from "@/view/components/app-component/TextAreaField.vue";

moment.tz.setDefault(process.env.VUE_APP_TIMEZONE);

export default {
  name: "create-invoice",
  mixins: [ValidationMixin, CommonMixin],
  data() {
    return {
      invoiceEditId: 0,
      formLoading: false,
      invoiceCreated: false,
      formValid: true,
      dbQuotation: {},
      dbVisit: {},
      dbEnquiry: {},
      related_type: 2,
      scheduleKey: Number(new Date()),
      dailogKey: Number(new Date()),
      uniqueId: Number(new Date()),
      is_allowed_recur_invoice: false,
      dbUpdateInvoice: {},
      recurringTypeList: [
        {
          value: "daily",
          text: "Daily",
        },
        {
          value: "weakly",
          text: "Weekly",
        },
        {
          value: "monthly",
          text: "Monthly",
        },
        {
          value: "yearly",
          text: "Yearly",
        },
      ],
      dbInvoice: {
        title: null,
        description: null,
        notify_billing_contact_person: null,
        notify_property_contact_person: null,
        barcode: null,
        customer_ref: null,
        date: null,
        payment_due: "2",
        due_date: null,
        ticket: 0,
        visit: 0,
        quotation: 0,
        payment_term_id: 0,
        payment_terms: null,
        term_condition_id: 0,
        term_conditions: null,
        admin_note_id: 0,
        admin_note: null,
        client_note_id: 0,
        client_note: null,
        billing_type: "one-off",

        recurring_type: "monthly",
        recurring_duration_new: 1,
        attachments: [
          {
            file: null,
            name: null,
            remark: null,
            suffix: null,
            tags: null,
            search: null,
            start_date: null,
            end_date: null,
            reminder_date: null,
          },
        ],
      },
      attachments: [
        {
          accepted_file: null,
          file_name: null,
          file_type: null,
        },
      ],
      adminNoteList: [],
      clientNoteList: [],
      termsConditionList: [],
      paymentTermList: [],
      paymentDueList: [],
      userList: [],
      todayDate: null,
      customerDialog: false,
      propertyType: null,
      propertyLabel: null,
      propertyDialog: false,
      contactType: null,
      contactDialog: false,
    };
  },
  components: {
    "customer-select": CustomerSelect,
    "person-select": PersonSelect,
    "address-select": AddressSelect,
    "line-item": LineItemV2,
    "date-picker": DatePicker,
    TinyMCE,
    // FileUpload,
    CreateUpdateTemplate,
    DocumnetList,
    RecurringSchedule,
    TextAreaField,
  },
  methods: {
    clearLocalStorage() {
      localStorage.removeItem("milesttone");
    },
    remove_row(target, index) {
      ObjectPath.del(this, `${target}.${index}`);
      this.$nextTick(() => {
        if (!this.attachments.length) {
          this.add_attachment_row();
        }
      });
    },
    add_attachment_row() {
      this.attachments.push({
        accepted_file: null,
        file_name: null,
        file_type: null,
      });
    },
    get_file_extension(file_type) {
      if (file_type) {
        return `.${file_type}`;
      }
      return null;
    },
    update_file_name(index) {
      const file = this.attachments[index].accepted_file;
      if (file) {
        this.attachments[index].file_name = file.name
          .split(".")
          .slice(0, -1)
          .join(".");
        this.attachments[index].file_type = file.name.split(".").pop();
        this.add_attachment_row();
      }
    },
    update_payment_term() {
      let output = this.lodash.find(this.paymentTermList, {
        id: this.dbInvoice.payment_term_id,
      });
      if (output) {
        this.dbInvoice.payment_terms = output.description;
      }
    },
    update_term_condition() {
      let output = this.lodash.find(this.termsConditionList, {
        id: this.dbInvoice.term_condition_id,
      });
      if (output) {
        this.dbInvoice.term_conditions = output.description;
      }
    },
    update_admin_note() {
      let output = this.lodash.find(this.adminNoteList, {
        id: this.dbInvoice.admin_note_id,
      });
      if (output) {
        this.dbInvoice.admin_note = output.description;
      }
    },
    update_client_note() {
      let output = this.lodash.find(this.clientNoteList, {
        id: this.dbInvoice.client_note_id,
      });
      if (output) {
        this.dbInvoice.client_note = output.description;
      }
    },
    uploadFiles() {
      let _this = this;
      return new Promise((resolve, reject) => {
        let formData = new FormData();

        let status = false;

        for (let i = 0; i < _this.attachments.length; i++) {
          if (_this.attachments[i].accepted_file) {
            status = true;
            formData.append(
              `files[${i}]`,
              _this.attachments[i].accepted_file,
              _this.attachments[i].file_name
            );
          }
        }

        if (!status) {
          resolve([]);
        } else {
          _this.$store
            .dispatch(UPLOAD, { url: "file-manager/upload", data: formData })
            .then(({ data }) => {
              resolve(data);
            })
            .catch((error) => {
              reject(error);
            });
        }
      });
    },
    openDialog(type) {
      this.customerDialog = false;
      this.propertyType = null;
      this.propertyLabel = null;
      this.propertyDialog = false;
      this.contactType = null;
      this.contactDialog = false;

      if (type == "customer") {
        this.customerDialog = true;
      } else {
        if (!this.vCustomerId) {
          ErrorEventBus.$emit("update:error", "Please Select Customer.");
          return false;
        }
        if (type == "billing") {
          alert(112);
          this.propertyType = "billing";
          this.propertyLabel = "Billing Location";
          this.propertyDialog = true;
        }
        if (type == "billing-contact") {
          this.contactType = "billing";
          this.contactDialog = true;
        }
        if (type == "property") {
          this.propertyType = "property";
          this.propertyLabel = "Site Location";
          this.propertyDialog = true;
        }
        if (type == "property-contact") {
          this.contactType = "property";
          this.contactDialog = true;
        }
      }
    },
    validateJob(type) {
      if (!this.vCustomerId) {
        ErrorEventBus.$emit("update:error", "Select Customer");
        return false;
      }

      if (!this.vPropertyId) {
        ErrorEventBus.$emit("update:error", "Select Service Location");
        return false;
      }

      if (!this.vPropertyContactId) {
        ErrorEventBus.$emit("update:error", "Select Service Contact Person");
        return false;
      }

      if (!this.vBillingId) {
        ErrorEventBus.$emit("update:error", "Select Billing Location");
        return false;
      }

      if (!this.vBillingContactId) {
        ErrorEventBus.$emit("update:error", "Select Billing Contact Person");
        return false;
      }

      if (!this.vSelectedLineItem || !this.vSelectedLineItem.length) {
        ErrorEventBus.$emit("update:error", "Select Line Item");
        return false;
      }

      const validateStatus = this.$refs.createInvoiceForm.validate();

      const formErrors = this.validateForm(this.$refs.createInvoiceForm);

      if (formErrors.length) {
        for (let i = 0; i < formErrors.length; i++) {
          ErrorEventBus.$emit("update:error", InitializeError(formErrors[i]));
        }
        return false;
      }

      if (validateStatus) {
        this.onSubmit(type);
      }
    },
    async onSubmit(type) {
      const new_attachments = await this.uploadFiles();
      this.dbInvoice.quotation = this.$route?.query?.quotation
        ? this.lodash.toSafeInteger(this.$route.query.quotation)
        : null;
      const request = {
        ...this.dbInvoice,
        attachments: [...this.dbInvoice.attachments, ...new_attachments],
        customer: this.vCustomerId,
        property: this.vPropertyId,
        sent: type == "send" ? 1 : 0,
        recurring:
          this.dbInvoice.billing_type == "recurring"
            ? this.invoiceRecurringSchedule
            : {},
        schedule:
          this.dbInvoice.billing_type == "recurring"
            ? this.invoiceScheduleOutput
            : [],
        billing: this.vBillingId,
        property_contact_person: this.vPropertyContactId,
        billing_contact_person: this.vBillingContactId,
        visit: this.lodash.toSafeInteger(this.dbVisit?.id),
        enquiry: this.lodash.toSafeInteger(this.dbEnquiry?.id),
        ticket: this.lodash.toSafeInteger(this.dbVisit?.ticket),
        milestone: this.$route?.query?.milestone
          ? this.lodash.toSafeInteger(this.$route.query.milestone)
          : null,
        milestone_amount: this.$route?.query?.milestone_amount
          ? this.$route.query.milestone_amount
          : null,
        payment_type: this.$route?.query?.payment
          ? this.$route?.query?.payment
          : null,
        "line-item": this.vSelectedLineItem,
        calculation: this.vCalculations,
      };

      this.formLoading = true;
      let requestType = POST;
      let requestURL = "invoice-v1";

      let invoiceId = this.$route?.query?.invoice ?? 0;

      if (invoiceId) {
        requestType = PATCH;
        requestURL = `invoice-v1/${invoiceId}`;
      }

      this.$store
        .dispatch(requestType, { url: requestURL, data: request })
        .then(({ data }) => {
          this.invoiceCreated = true;
          this.$store.dispatch(CLEAR_CUSTOMER);
          this.$store.dispatch(RESET_CREATE_STATE);
          this.$store.dispatch(CLEAR_LINE_ITEM);
          /*      this.clearLocalStorage(); */
          this.$nextTick(() => {
            if (requestType == POST) {
              this.$router.push(this.getDefaultRoute("invoice"));
            } else {
              this.$router.push(
                this.getDefaultRoute("invoice.detail", {
                  params: { id: data?.id },
                })
              );
            }
          });
        })
        .catch(() => {
          this.formLoading = false;
        });
    },
    get_attributes() {
      this.formLoading = true;

      this.$store
        .dispatch(QUERY, {
          url: "invoice/options",
          data: {
            ...this.$route.query,
            invoice: ObjectPath.get(this.$route, "params.id"),
          },
        })
        .then((response) => {
          this.is_allowed_recur_invoice = ObjectPath.get(
            response,
            "data.is_allowed_recur_invoice"
          );
          this.dbInvoice.barcode = ObjectPath.get(response, "data.barcode");
          this.dbUpdateInvoice = ObjectPath.get(response, "data");
          if (this.dbUpdateInvoice?.invoice?.barcode) {
            this.dbInvoice.barcode = this.dbUpdateInvoice?.invoice?.barcode;
          }
          this.dbQuotation = ObjectPath.get(response, "data.quotation");
          this.dbEnquiry = ObjectPath.get(response, "data.enquiry");
          this.dbVisit = ObjectPath.get(response, "data.visit");

          this.$store.dispatch(SET_BREADCRUMB, [
            { title: "Invoice", route: "invoice" },
            { title: "Create" },
            { title: this.dbInvoice.barcode },
          ]);

          this.$store.dispatch(
            SET_UOM,
            ObjectPath.get(response, "data.uom_list", [])
          );
          this.$store.dispatch(
            SET_GST,
            ObjectPath.get(response, "data.entity.gstvalue", 0)
          );
          this.adminNoteList = ObjectPath.get(
            response,
            "data.admin_notes_list",
            []
          );
          this.clientNoteList = ObjectPath.get(
            response,
            "data.client_notes_list",
            []
          );
          this.paymentTermList = ObjectPath.get(
            response,
            "data.payment_terms_list",
            []
          );
          this.termsConditionList = ObjectPath.get(
            response,
            "data.term_conditions_list",
            []
          );
          this.paymentDueList = ObjectPath.get(
            response,
            "data.payment_terms",
            []
          );

          let defaultPaymentTermObject = this.lodash.find(
            this.paymentTermList,
            {
              primary: 1,
            }
          );
          if (defaultPaymentTermObject) {
            this.dbInvoice.payment_term_id = defaultPaymentTermObject.id;
            this.dbInvoice.payment_terms = defaultPaymentTermObject.description;
          }

          let defaultTermConditionObject = this.lodash.find(
            this.termsConditionList,
            {
              primary: 1,
            }
          );
          if (defaultTermConditionObject) {
            this.dbInvoice.term_condition_id = defaultTermConditionObject.id;
            this.dbInvoice.term_conditions =
              defaultTermConditionObject.description;
          }

          let defaultClientObject = this.lodash.find(this.clientNoteList, {
            primary: 1,
          });
          if (defaultClientObject) {
            this.dbInvoice.client_note_id = defaultClientObject.id;
            this.dbInvoice.client_note = defaultClientObject.description;
          }

          let defaultAdminObject = this.lodash.find(this.adminNoteList, {
            primary: 1,
          });
          if (defaultAdminObject) {
            this.dbInvoice.admin_note_id = defaultAdminObject.id;
            this.dbInvoice.admin_note = defaultAdminObject.description;
          }
          if (this.dbUpdateInvoice?.invoice?.id) {
            this.dbInvoice.admin_note_id =
              this.dbUpdateInvoice?.invoice?.admin_note_id;
            this.dbInvoice.admin_note =
              this.dbUpdateInvoice?.invoice.admin_note;

            this.dbInvoice.client_note_id =
              this.dbUpdateInvoice?.invoice?.client_note_id;
            this.dbInvoice.client_note =
              this.dbUpdateInvoice?.invoice.client_note;

            this.dbInvoice.term_condition_id =
              this.dbUpdateInvoice?.invoice.term_condition_id;
            this.dbInvoice.term_conditions =
              this.dbUpdateInvoice?.invoice.term_conditions;

            this.dbInvoice.payment_term_id =
              this.dbUpdateInvoice?.invoice.payment_term_id;
            this.dbInvoice.payment_terms =
              this.dbUpdateInvoice?.invoice.payment_terms;

            this.dbInvoice.title = this.dbUpdateInvoice?.invoice?.title;
            this.dbInvoice.billing_type = this.getInvoiceType(
              this.dbUpdateInvoice?.invoice?.billing_type
            );
            this.dbInvoice.recurring_duration_new =
              this.dbUpdateInvoice?.invoice?.recurring_duration_new;
            this.dbInvoice.recurring_type =
              this.dbUpdateInvoice?.invoice?.recurring_type;
            this.dbInvoice.description =
              this.dbUpdateInvoice?.invoice?.description;
            this.dbInvoice.customer_ref =
              this.dbUpdateInvoice?.invoice?.customer_ref;
            this.dbInvoice.date = this.dbUpdateInvoice?.invoice?.date;

            this.dbInvoice.notify_billing_contact_person =
              this.dbUpdateInvoice?.invoice?.notify_billing_contact_person;
            this.dbInvoice.notify_property_contact_person =
              this.dbUpdateInvoice?.invoice?.notify_property_contact_person;

            this.dbInvoice.attachments = [];

            this.$store.commit(
              SET_CUSTOMER,
              this.dbUpdateInvoice?.invoice?.customer_relation
            );
            this.$store.commit(
              SET_PROPERTY,
              this.dbUpdateInvoice?.invoice?.property_relation
            );
            this.$store.commit(
              SET_PROPERTY_CONTACT,
              this.dbUpdateInvoice?.invoice?.property_contact_person_relation
            );
            this.$store.commit(
              SET_BILLING,
              this.dbUpdateInvoice?.invoice?.billing_relation
            );
            this.$store.commit(
              SET_BILLING_CONTACT,
              this.dbUpdateInvoice?.invoice?.billing_contact_person_relation
            );

            this.$store.dispatch(SET_EDIT_CALCULATION, {
              ctx_discount_label:
                this.dbUpdateInvoice?.invoice?.ctx_discount_label ?? null,
              ctx_discount_value:
                this.dbUpdateInvoice?.invoice?.ctx_discount_value ?? 0,
              ctx_discount_type:
                this.dbUpdateInvoice?.invoice?.ctx_discount_type ?? 1,
              discount_type: this.dbUpdateInvoice?.invoice?.discount_type ?? 1,
              discount_value:
                this.dbUpdateInvoice?.invoice?.discount_value ?? 0,
              tax_active: this.dbUpdateInvoice?.invoice?.tax_active ?? false,
              adjustment: this.dbUpdateInvoice?.invoice?.adjustment ?? 0,
              show_price: true,
              discount_level:
                this.dbUpdateInvoice?.invoice?.discount_level ?? "transaction",
            });

            this.$store.dispatch(
              SET_EDIT_LINE_ITEM,
              this.dbUpdateInvoice?.invoice?.line_item ?? []
            );
            this.$store.dispatch(SET_BREADCRUMB, [
              { title: "Invoice", route: "invoice" },
              { title: "Update" },
              { title: this.dbInvoice.barcode },
            ]);
          }
          if (this.dbQuotation?.id) {
            this.dbUpdateInvoice.invoice = this.dbQuotation;
            this.related_type = 1;
            this.dbInvoice.title = this.dbQuotation?.title;
            this.dbInvoice.billing_type = this.getInvoiceType(
              this.dbQuotation?.billing_type
            );
            this.dbInvoice.recurring_duration_new =
              this.dbQuotation?.recurring_duration_new;
            this.dbInvoice.recurring_type = this.dbQuotation?.recurring_type;
            this.dbInvoice.description = this.dbQuotation?.description;
            this.dbInvoice.customer_ref = this.dbQuotation?.reference;

            this.dbInvoice.notify_billing_contact_person =
              this.dbQuotation?.notify_billing_contact_person;
            this.dbInvoice.notify_property_contact_person =
              this.dbQuotation?.notify_property_contact_person;

            this.dbInvoice.client_note_id = 0;
            this.dbInvoice.client_note = this.dbQuotation?.client_note;

            this.dbInvoice.admin_note_id = 0;
            this.dbInvoice.admin_note = this.dbQuotation?.admin_note;
            this.dbInvoice.attachments = [];
            this.dbInvoice.billing_type = "one-off";
            this.$store.commit(
              SET_CUSTOMER,
              this.dbQuotation?.customer_relation
            );
            this.$store.commit(
              SET_PROPERTY,
              this.dbQuotation?.property_relation
            );
            this.$store.commit(
              SET_PROPERTY_CONTACT,
              this.dbQuotation?.property_contact_person_relation
            );
            this.$store.commit(SET_BILLING, this.dbQuotation?.billing_relation);
            this.$store.commit(
              SET_BILLING_CONTACT,
              this.dbQuotation?.billing_contact_person_relation
            );

            this.$store.dispatch(SET_EDIT_CALCULATION, {
              ctx_discount_label: this.dbQuotation?.ctx_discount_label ?? null,
              ctx_discount_value: this.dbQuotation?.ctx_discount_value ?? 0,
              ctx_discount_type: this.dbQuotation?.ctx_discount_type ?? 1,
              discount_type: this.dbQuotation?.discount_type ?? 1,
              discount_value: this.dbQuotation?.discount_value ?? 0,
              tax_active: this.dbQuotation?.tax_active ?? false,
              adjustment: this.dbQuotation?.adjustment ?? 0,
              show_price: true,
              discount_level: this.dbQuotation?.discount_level ?? "transaction",
            });

            this.$store.dispatch(
              SET_EDIT_LINE_ITEM,
              this.dbQuotation?.line_item ?? []
            );
          }
          if (this.dbEnquiry?.id) {
            this.dbUpdateInvoice.invoice = this.dbEnquiry;
            this.related_type = 6;
            this.dbInvoice.title = this.dbEnquiry?.title;
            this.dbInvoice.description = this.dbEnquiry?.problem;
            this.dbInvoice.customer_ref = this.dbEnquiry?.reference;
            this.dbInvoice.attachments = [];
            this.dbInvoice.billing_type = "one-off";
            this.$store.commit(SET_CUSTOMER, this.dbEnquiry?.customer_relation);
            this.$store.commit(SET_PROPERTY, this.dbEnquiry?.property_relation);
            this.$store.commit(
              SET_PROPERTY_CONTACT,
              this.dbEnquiry?.property_contact_person_relation
            );
            this.$store.commit(SET_BILLING, this.dbEnquiry?.billing_relation);
            this.$store.commit(
              SET_BILLING_CONTACT,
              this.dbEnquiry?.billing_contact_person_relation
            );

            this.$store.dispatch(SET_EDIT_CALCULATION, {
              ctx_discount_label: this.dbEnquiry?.ctx_discount_label ?? null,
              ctx_discount_value: this.dbEnquiry?.ctx_discount_value ?? 0,
              ctx_discount_type: this.dbEnquiry?.ctx_discount_type ?? 1,
              discount_type: this.dbEnquiry?.discount_type ?? 1,
              discount_value: this.dbEnquiry?.discount_value ?? 0,
              tax_active: this.dbEnquiry?.tax_active ?? false,
              adjustment: this.dbEnquiry?.adjustment ?? 0,
              show_price: true,
              discount_level: this.dbEnquiry?.discount_level ?? "transaction",
            });

            this.$store.dispatch(
              SET_EDIT_LINE_ITEM,
              this.dbEnquiry?.line_item ?? []
            );
          }

          if (this.dbVisit?.id) {
            this.dbUpdateInvoice.invoice = this.dbVisit;
            this.related_type = 3;
            this.dbInvoice.title = this.dbVisit?.title;
            // this.dbInvoice.billing_type = this.dbVisit?.billing_type;
            this.dbInvoice.recurring_duration_new =
              this.dbVisit?.recurring_duration_new;
            this.dbInvoice.recurring_type = this.dbVisit?.recurring_type;
            this.dbInvoice.description = this.dbVisit?.description;
            this.dbInvoice.customer_ref = this.dbVisit?.reference;
            this.dbInvoice.billing_type = "one-off";

            /*      this.dbInvoice.notify_billing_contact_person =
              this.dbVisit?.notify_billing_contact_person;
            this.dbInvoice.notify_property_contact_person =
              this.dbVisit?.notify_property_contact_person; */

            this.dbInvoice.client_note_id = 0;
            this.dbInvoice.client_note = this.dbVisit?.client_note;

            this.dbInvoice.admin_note_id = 0;
            this.dbInvoice.admin_note = this.dbVisit?.admin_note;
            this.dbInvoice.term_conditions = this.dbVisit?.term_conditions;
            this.dbInvoice.term_condition_id = this.dbVisit?.term_condition_id;
            this.dbInvoice.attachments = [];

            this.$store.commit(SET_CUSTOMER, this.dbVisit?.customer_relation);
            this.$store.commit(SET_PROPERTY, this.dbVisit?.property_relation);
            this.$store.commit(
              SET_PROPERTY_CONTACT,
              this.dbVisit?.property_contact_person_relation
            );
            this.$store.commit(SET_BILLING, this.dbVisit?.billing_relation);
            this.$store.commit(
              SET_BILLING_CONTACT,
              this.dbVisit?.billing_contact_person_relation
            );
          }
        })
        .catch((error) => {
          this.logError(error);
          /*  this.goBack(); */
        })
        .finally(() => {
          this.formLoading = false;
        });
    },
    getInvoiceType(type) {
      if (type == "one-off") {
        return "one-off";
      } else if (type == "recurring-based-on-quote") {
        return "recurring";
      } else if (type == "recurring") {
        return "recurring";
      }
    },
  },
  watch: {
    /* $route (to, from){
          if(to != from){
            this.clearLocalStorage()
          }
      } */
    /*  vCustomerId(param) {
          this.$store.dispatch(GET_CUSTOMER_RELATED, {
            id: param,
            type: ["quotation", "visit","invoice"],
          });
        }, */
  },
  mounted() {
    this.get_attributes();
    this.invoiceEditId = this.lodash.toSafeInteger(this.$route.params.id);
    if (this.dbUpdateInvoice?.invoice?.id > 1) {
      this.$store.dispatch(SET_BREADCRUMB, [
        { title: "Invoice", route: "invoice" },
        { title: "Update" },
      ]);
    } else {
      this.$store.dispatch(SET_BREADCRUMB, [
        { title: "Invoice", route: "invoice" },
        { title: "Create" },
      ]);
    }
  },
  destroyed() {
    this.$store.dispatch(CLEAR_INVOICE);
    this.$store.dispatch(CLEAR_CUSTOMER);
    this.$store.dispatch(RESET_CREATE_STATE);
    this.$store.dispatch(CLEAR_LINE_ITEM);
  },
  beforeDestroy() {
    KTCookie.setCookie("kt_aside_toggle_state", "off");
    localStorage.setItem("kt_aside_toggle_state", "off");
  },
  created() {
    this.todayDate = moment().format("YYYY-MM-DD");
    this.dbInvoice.date = this.todayDate;
    this.dbInvoice.ticket = ObjectPath.get(this.$route, "query.ticket");
    this.dbInvoice.visit = ObjectPath.get(this.$route, "query.visit");
    this.dbInvoice.quotation = ObjectPath.get(this.$route, "query.quotation");
  },
  beforeCreate() {
    KTCookie.setCookie("kt_aside_toggle_state", "on");
    localStorage.setItem("kt_aside_toggle_state", "on");
    this.$store.dispatch(CLEAR_CUSTOMER);
    this.$store.dispatch(RESET_CREATE_STATE);
    this.$store.dispatch(CLEAR_LINE_ITEM);
  },
  computed: {
    ...mapGetters([
      "vRelatedQuotations",
      "vRelatedVisits",
      "vCustomer",
      "vProperty",
      "vPropertyContact",
      "vBilling",
      "vBillingContact",
      "vCustomerId",
      "vPropertyId",
      "vPropertyContactId",
      "vBillingId",
      "vBillingContactId",
      "vSelectedLineItem",
      "vCalculations",
      "recurringInvoiceScheduleTypeList",
      "invoiceRecurringSchedule",
      "invoiceScheduleOutput",
      "customError",
    ]),
  },
  beforeRouteLeave(to, from, next) {
    const _this = this;
    if (_this.invoiceCreated) {
      next();
    } else {
      _this.routePreventDialog = true;
      PreventDialogEventBus.$on("stay-on-page", function () {
        _this.routePreventDialog = false;
        next(false);
      });
      PreventDialogEventBus.$on("leave-page", function () {
        _this.routePreventDialog = false;
        next();
      });
    }
  },
};
</script>

<style lang="scss" scoped>
.invoice-create-page {
  border-top: solid 8px #f7941e;
  label {
    padding-left: 5px;
    font-weight: 600;
  }
  p {
    padding-left: 5px;
  }
}
.form-action {
  display: flex;
  justify-content: space-between;

  .form-title {
    font-size: 24px;
    font-family: "Poppins", Helvetica, Arial, sans-serif;
    font-weight: 700;
    color: #24326d;
    letter-spacing: 1.5px;
    line-height: 1.11em;
    .form-title-link {
      color: #4d6974;
      border-bottom: dashed 2px #4d6974;
    }
  }
}
</style>
